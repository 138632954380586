import {graphql} from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import Hero from "../sections/main/hero";
import UpdatedStatsShowcase from "../components/Sections/updatedStatsShowcase";
import LazyHydrate from "react-lazy-hydration";
import TutorsSlider from "../components/Sections/Sliders/tutorsSlider";
import TutorSchoolLogos from "../components/Sections/tutorSchoolLogos";
import leadFormBgImage from "../assets/images/ap-banner.svg";
import LeadForm from "../components/Interactive/leadForm";
import statsImgRatio from "../assets/images/stats-img-ratio.png";
import statsImgStudents from "../assets/images/stats-img-students.png";
import statsImgReferral from "../assets/images/stats-img-referral.png";
import ThreeColumn from "../components/Collections/threeColumn";
import TwoColumn from "../components/Collections/twoColumn";
import Template from "../components/Interactive/video";
import TestimonialsSlider from "../components/Sections/Sliders/testimonialsSlider";
import Images from "../components/Interactive/image";

const SLUG_TO_DISPLAY_NAME = {
    act: "ACT",
    sat: "SAT"
}

const SLUG_TO_EXAM_FORM_FIELD_VALUE = {
    act: "ACT",
    sat: "SAT"
}

const HeroSection = ({data}) => {

    // override the parts we don't need
    const heroSection = data?.sanityHomepage?.heroSection;
    heroSection.ctaBtn.button.textLink = "#form";
    heroSection.heroTitle = [{align: "left", text: <React.Fragment>{data.goalName}<sup>®</sup> tutors</React.Fragment>}, {align: "left", text:"that open doors", className: "hero-title-open-doors"}];
    heroSection.heroDescription._rawContent[0].children[0].text = "Individualized tutoring targeted to your goals, timeline, and personality";
    heroSection.className = "ppcHero";
    heroSection.ctaCname = "ppc_page_hero_cta_button"
    heroSection.ctaBtn.button.text = "Learn more";
    return <Hero data={heroSection} />;
}

const WhyEnhancedPrepSection = ({data}) => {
    return <Flex className='pillar-page-section'>
        <Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '125px'}} marginBottom={{base: '60px', lg:'100px'}}>
            <Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Why Enhanced Prep?</Text>
            <Box width='100%'>
                <UpdatedStatsShowcase />
            </Box>
        </Flex>
    </Flex>;
}

const TutorSection = (allSanityTutor) => {

    return <Flex className='pillar-page-section' backgroundColor='#F4F4F4'>
        <Flex className='pillar-page-section-contents' marginTop={{base: '30px', lg: '60px'}} marginBottom={{base: '75px', lg: '95px'}}>
            <Text as='h3' fontSize={{base: '26px', lg: '36px'}} marginBottom={{base: "30px", lg: "30px"}} textAlign='center'>Tutors with Superior Credentials & Expertise</Text>
            <LazyHydrate whenVisible><TutorsSlider data={allSanityTutor?.data?.nodes} noShuffle={true} /></LazyHydrate>

            <Text as='h4' fontSize={{base: '20px', lg: '24px'}} marginTop={{base: '30px', lg: '60px'}} marginBottom={{base: "30px", lg: "30px"}} textAlign='center'>Tutors are graduates of top universities</Text>
            <TutorSchoolLogos />
        </Flex>
    </Flex>;
}

const overrideFormDef = (formDef) => {
    let firstPage = formDef?.find(page => page?.pagePrompt === "Tell us more about yourself");
    if (!! firstPage) {
        firstPage.pagePrompt = "Tell us about yourself";
        firstPage.questions[0].color = "gray3d";
        firstPage.questions[0].backgroundColor = "white";
        firstPage.questions[0].width = "270px";
    }
}

const getFormTitleForExam = (exam) => {
    return <React.Fragment>Get matched with an {exam}<sup id='topFormRegTradeMark'>®</sup> tutor</React.Fragment>
}

const FormSection = ({exam, isLeadFormTop}) => {

    return <Box bgImg={leadFormBgImage} id="form">
        <LeadForm data={
            {formType: 'homepage',
            title: isLeadFormTop ? getFormTitleForExam(exam) : `Get matched with a tutor`,
            skippedPages: ["How can we help?"],
            formDefOverrider: (formDef) => overrideFormDef(formDef),
            addedFormFields: {exam: exam},
            onTop: isLeadFormTop,
        }} />
    </Box>
}

const NumbersSection = () => {
    const data = [
        {
            image: statsImgRatio,
            imgAlt: 'stats-img-ratio',
            stats: '95%',
            description: 'Students accepted into one of their top schools'
        },
        {
            image: statsImgStudents,
            imgAlt: 'stats-img-students',
            stats: '17,500+',
            description: 'Students served by Enhanced Prep'
        },
        {
            image: statsImgReferral,
            imgAlt: 'stats-img-referral',
            stats: '3',
            description: 'Avg. number of new students referred by satisfied families'
        }
    ]

    return <Flex className='pillar-page-section' width="100%">
        <Flex width="100%" className='pillar-page-section-contents' mt="60px" mb="60px">
            <LazyHydrate whenVisible>
                <Flex justify={{base: 'flex-start', lg: 'space-between'}}
                      direction={{base: 'column', lg: 'row'}} w='100%'>
                 {data.map((ele, idx) =>
                     <Flex key={idx} w={{base: '100%', lg: '33.333%'}} direction='column' align='center'>
                         <Images image={ele.image} alt={ele.imgAlt} />
                         <Text fontSize='48px' color='primary700' fontFamily='SourceSansPro700' mt='24px' mb='16px'>
                             {ele.stats}
                         </Text>
                         <Text maxW='230px' textAlign='center'
                               mb={{base: idx === data.length - 1 ? '0' : '45px',
                                    lg: 0}}>{ele.description}</Text>
                 </Flex>)}
                </Flex>
            </LazyHydrate>
        </Flex>
    </Flex>
}

const StudentSuccessSection = ({data, title}) => {
    data.content1.content[0].asset.altText = "Student Seth";
    return <Flex className='pillar-page-section' backgroundColor="#E9F8FB">
        <Flex className='pillar-page-section-contents' marginTop="60px" marginBottom="60px">
            <Text as='h3'
                  display='block'
                  fontSize={{base: '26px', lg: '36px'}}
                  lineHeight={{base: "40px", md: "50px"}}
                  fontFamily='merriweather'
                  textAlign='center'
            >
                {title}
            </Text>

            <Flex className="student-success-columns" marginTop={{base: "45px", lg: "60px"}} width={{base: "100%", sm: "80%"}} align="center" justify="center">
                <TwoColumn data={data}></TwoColumn>
            </Flex>
        </Flex>
    </Flex>;
}

const ThreePillarsSection = ({data}) => {

    data.allSanityPillarCards.nodes[1].pillars.content1.content[0].bgColor = "rgb(0, 111, 130)";
    data.allSanityPillarCards.nodes[1].pillars.content2.content[0].bgColor = "rgb(0, 111, 130)";
    data.allSanityPillarCards.nodes[1].pillars.content3.content[0].bgColor = "rgb(0, 111, 130)";

    return <Flex className='pillar-page-section three-pillar-section'>
        <Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '90px'}} marginBottom={{base: '60px', lg: '130px'}}>

            <Box marginBottom='30px'>
                <Text as='h4' fontFamily='Merriweather' fontSize={{base: '27px', lg: '30px'}} textAlign="center">How Enhanced Prep Maximizes
                    Scores</Text>
            </Box>
            <Text as='span' fontFamily='sourceSansPro' fontSize={{base: '18px', lg: '21px'}} textAlign='center' marginBottom='20px'>Our learning model,
                the Three Pillars, helps students maximize their standardized test scores.</Text>

            <LazyHydrate whenVisible><ThreeColumn stretch={true} data={data?.allSanityPillarCards?.nodes[1]?.pillars}></ThreeColumn></LazyHydrate>
        </Flex>
    </Flex>;
}

const TestimonialSection = ({data}) => {
    return <Flex className='pillar-page-section ppc-testimonial-section' backgroundColor='#E9F9FB'>
        <Flex className='pillar-page-section-contents' marginTop={{base: '67px', lg: '86px'}} marginBottom={{base: '44px', lg: '86px'}}>
            <Box marginBottom={{base: '10px', lg: '40px'}}>
                <Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Testimonials</Text>
            </Box>
            <LazyHydrate whenVisible><Template data={{videoUrl: "https://fast.wistia.com/embed/medias/tfc6cbxk91.jsonp", desktopWidthPixels: "600"}} locatorString="testimonial_video"/></LazyHydrate>
            <LazyHydrate whenVisible><TestimonialsSlider data={data?.allSanityReview?.nodes[0]} /></LazyHydrate>
        </Flex>
    </Flex>;
}

export default function ppcPageTemplate({data, pageContext, location}) {
    if (typeof document === "undefined") {
        return;
    }

    const {slug, header, footer, allSanityTutor} = pageContext
    const seo = {
        title: 'Expert Tutoring for ' + (SLUG_TO_DISPLAY_NAME[slug] || slug) + ' | Enhanced Prep',
        metaDescription: 'Enhanced Prep matches you with expert ' + (SLUG_TO_DISPLAY_NAME[slug] || slug)  + ' tutors who work with you on a personalized study plan. 95% of students are accepted into their top schools.',
        crazyEggScript: <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0114/9898.js" async="async" ></script>
    };
    data.goalName = SLUG_TO_DISPLAY_NAME[slug];
    header.justLogo = true;
    let studentSuccessTitle = null;

    let isLeadFormTopVersion = false;
    let path = document.location.pathname;
    if (path.indexOf("/v2/") >= 0) {
        isLeadFormTopVersion = true;
    }

    if (slug === 'act') {
        allSanityTutor.nodes = allSanityTutor.nodes.filter(tutor => tutor.tutorName !== 'Rachel')
            .sort(
                (tutor1, tutor2) =>
                    tutor1.tutorName === 'Amelia' ? -1 :
                        tutor2.tutorName === 'Amelia' ? 1 : 0);
        studentSuccessTitle = "Seth increased his score and got into his dream school";
    } else if (slug === 'sat') {
        allSanityTutor.nodes = allSanityTutor.nodes.filter(tutor => tutor.tutorName !== 'Amelia')
            .sort(
                (tutor1, tutor2) =>
                    tutor1.tutorName === 'Rachel' ? -1 :
                        tutor2.tutorName === 'Rachel' ? 1 : 0);
        studentSuccessTitle = "Jackson pursued his passion & sailed towards his dream school";
    }

    return (
        <Layout header={header} footer={footer}>
            <Seo data={seo} location={location} />
            <Flex flexDirection='column' overflowX='hidden'>
                {isLeadFormTopVersion ? <FormSection exam={SLUG_TO_EXAM_FORM_FIELD_VALUE[slug]} isLeadFormTop={true} /> : <HeroSection data={data} /> }
                <WhyEnhancedPrepSection data={data}/>
                <TutorSection data={allSanityTutor}/>
                {!isLeadFormTopVersion && <FormSection exam={SLUG_TO_EXAM_FORM_FIELD_VALUE[slug]} isLeadFormTop={false} />}
                <NumbersSection />
                <StudentSuccessSection data={pageContext.node.studentSuccess} title={studentSuccessTitle} />
                <ThreePillarsSection data={data} />
                <TestimonialSection data={data}/>
            </Flex>
        </Layout>
    )
}

export const query = graphql`
  query TutoringChoicesPageQuery {
    sanityHomepage {
		heroSection {
        _rawHeroBackgroundColor(resolveReferences: {maxDepth: 10})
        heroDescription {
          _rawContent(resolveReferences: {maxDepth: 10})
          desktopWidth
          marginBottom
        }
        heroTitle {
          align
          text
        }
        heroImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaBtn {
          button {
            ...SanityCtaButton
          }
        }
        sectionHeight
      }
    }
	  allSanityPillarCards {
		  nodes {
		  	...SanityPillarCards
		  }
	  }
  allSanityReview(filter: {id: {eq: "-3a3c139d-c07f-58b7-af6d-176fbc4049b8"}}) {
    nodes {
      reviews {
        about
        name
        quote
      }
      _id
      _key
      id
    }
  }
  }
`